import "./invoice.css";
import React, { useContext, useEffect, useState } from "react";
import { LabelContext } from "../../hooks/InputFeild";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Loader from "../../loader/Loader";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function calcRooms(rooms)
{

  let count= 0
  Object.values(rooms).map(r => {

    Object.values(r).map(a => count += a.num)

  })

  return count
}

export default function FinalInvoice() {
  const value = useContext(LabelContext);
  const { t } = useTranslation();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let [loading, setLoading] = useState(true);

  let [invoiceData, setInvoiceData] = useState({});
  const [roomsData, setRoomsData] = useState([])
  const [uponRequest, setUponRequest] = useState([])
  let params = useParams();

  useEffect(() => {
    let head = "Bearer " + localStorage.getItem("access_token");
    let headers = {
      headers: { Authorization: head, "Accept-language": i18next.language },
    };

    axios.get("https://api.10sap.com/api/userUnit/" + params.id, headers).then(
      (response) => {
        const data  = response.data.UserUnit.data
        setInvoiceData({...data, rooms: calcRooms(data.unitRooms)});
        setLoading(false);

        setUponRequest(response.data.upon_request)

        setRoomsData(  Object.values(data.unitRooms)
        .map((r) => Object.values(r).map((a) => a))
        .flat(2))
      },
      (error) => {
        setInvoiceData([]);
        setLoading(false);
      }
    );
  }, []);

  const download = () => {
    var downloadSection = document.getElementById("download_section");
    var cWidth = downloadSection.offsetWidth;
    var cHeight = downloadSection.offsetHeight;

    var topLeftMargin = 40;
    var pdfWidth = cWidth + topLeftMargin * 2;
    var pdfHeight = pdfWidth * 1.5 + topLeftMargin * 2;
    var canvasImageWidth = cWidth;
    var canvasImageHeight = cHeight;
    var totalPDFPages = Math.ceil(cHeight / pdfHeight) - 1;

    html2canvas(downloadSection, { allowTaint: true }).then(function (canvas) {
      canvas.getContext("2d");
      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);
      pdf.addImage(
        imgData,
        "JPG",
        topLeftMargin,
        topLeftMargin,
        canvasImageWidth,
        canvasImageHeight
      );
      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(pdfWidth, pdfHeight);
        pdf.addImage(
          imgData,
          "JPG",
          topLeftMargin,
          -(pdfHeight * i) + topLeftMargin * 0,
          canvasImageWidth,
          canvasImageHeight
        );
      }
      pdf.save("10sap-invoice.pdf");
    });
  };

  let titles = {
    design: t("Design Fees"),
    architectural: t("Architectural Modifications"),
    electrical: t("Electrical & lighting systems"),
    plumbing: t("Plumbing & Sanitary works"),
    bathtub: t("Shower systems"),
    ac: t("Ventilation works (Air conditioners)"),
    plastering: t("Plaster Works"),
    gypsum: t("False Ceiling (Gypsum boards)"),
    reception_flooring: t("Reception Flooring"),
    rooms_flooring: t("Rooms and corridor Flooring"),
    //skirting: t("Skirting"),
    bathrooms_and_kitchen: t("Bathrooms & Kitchen cladding tiles"),
    stairs: t("Stair & Handrail"),
    paints: t("Paint works"),
    doorsInterior: t("Internal Doors selections"),
    mainDoor: t("External door selections"),
  };

  return (
    <React.Fragment>
      <div className="cs-container">
        <div className="cs-invoice cs-style1">
          {loading && (
            <Grid sx={{ paddingTop: "35px", paddingBottom: "35px" }}>
              <Loader loading={loading} />
            </Grid>
          )}

          {!loading && Object.keys(invoiceData).length > 0 && (
            <React.Fragment>
              <div className="cs-invoice_in" id="download_section">
                <div className="cs-invoice_head cs-type1 cs-mb25">
                  <div className="cs-invoice_right cs-text_right">
                    <div className="cs-logo cs-mb5">
                      <img id="logo" src="/images/logo.png" alt="Logo" />
                    </div>
                  </div>
                  <div className="cs-invoice_left">
                    <p className="invoiceTitle">{t("Invoice")}</p>
                    <p className="cs-invoice_number cs-primary_color cs-mb5 cs-f16">
                      <b className="cs-primary_color">{t("Invoice No")}:</b>{" "}
                      #SM75692
                    </p>
                  </div>
                </div>

                <div className="cs-invoice_head cs-mb10 cs-type1 invoiceData">
                  <div className="cs-invoice_left">
                    <b className="cs-primary_color">{t("User Name")}: </b>
                    <span>{userData.name}</span>
                  </div>
                  <div className="cs-invoice_right cs-text_right">
                    <b className="cs-primary_color">{t("Date")} : </b>
                    <span id="date">{invoiceData.date}</span>
                  </div>
                </div>

                <div className="cs-invoice_head cs-mb10 unitData">
                  <div className="cs-invoice_left">
                    <b className="cs-primary_color">{t("Unit Address")}: </b>
                    <span>{invoiceData.area_name}</span>
                  </div>
                  <div className="cs-invoice_left">
                    <b className="cs-primary_color">{t("Unit Type")} : </b>
                    {invoiceData.selectedUnitTypeName}
                  </div>
                  <div className="cs-invoice_left">
                    <b className="cs-primary_color">{t("Unit Area")} : </b>
                    <span>{invoiceData.details.unitArea}</span>
                  </div>
                  <div className="cs-invoice_left">
                    <b className="cs-primary_color">{t("Unit Rooms")}: </b>
                    <span>{invoiceData.rooms}</span>
                  </div>
                </div>

                <div style={{marginBottom: "20px"}} className="cs-table cs-style1 invoiceTable">
                  <div className="cs-round_border">
                    <div className="cs-table_responsive">
                      <table>
                        <thead>
                          <tr>
                            <th className="cs-width_6 cs-semi_bold cs-primary_color cs-focus_bg">
                              {t("Room")}
                            </th>
                        
                            <th
                              style={{ textAlign: "center" }}
                              className="cs-width_6 cs-text_center cs-semi_bold cs-primary_color cs-focus_bg"
                            >
                              {t("Count")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {roomsData.map((room, i) => {
                           
                              return (
                                <tr key={i}>
                                  <td className="cs-width_6 bold-text">
                                    {room.roomObj.label}
                                  </td>
                                  <td className="cs-width_6 cs-text_center bold-text">
                                    {room.num}
                                  </td>
                                </tr>
                              )
                            })   
                          }
                         
                          </tbody>
                        </table>

                        </div>
                        </div>
                </div>

                <div className="cs-table cs-style1 invoiceTable">
                  <div className="cs-round_border">
                    <div className="cs-table_responsive">
                      <table>
                        <thead>
                          <tr>
                            <th className="cs-width_4 cs-semi_bold cs-primary_color cs-focus_bg">
                              {t("Description")}
                            </th>
                            <th
                              style={{ textAlign: "center" }}
                              className="cs-width_4 cs-text_center cs-semi_bold cs-primary_color cs-focus_bg"
                            >
                              {t("Plan")}
                            </th>
                            <th
                              style={{ textAlign: "center" }}
                              className="cs-width_4 cs-text_center cs-semi_bold cs-primary_color cs-focus_bg"
                            >
                              {t("Price")}
                            </th>
                            <th
                              style={{ textAlign: "center" }}
                              className="cs-width_4 cs-text_center cs-semi_bold cs-primary_color cs-focus_bg"
                            >
                              {t("Percentage")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(titles).map((field) => {
                            if (
                              !(
                                invoiceData.details.manyFloor === "1" &&
                                field === "stairs"
                              )
                            ) {
                              return (
                                <tr key={field}>
                                  <td className="cs-width_4 bold-text">
                                    {titles[field]}
                                  </td>
                                  <td className="cs-width_4 cs-text_center">
                                    {(field != "plastering" ||
                                      (field === "plastering" &&
                                        invoiceData.plasteringChoice)) &&
                                      invoiceData.choosenPlans[field][
                                        "package_name_" + i18next.language
                                      ]}
                                    {field === "plastering" &&
                                      !invoiceData.plasteringChoice && <>_</>}
                                  </td>
                                  <td className="cs-width_4 cs-text_center bold-text">
                                    {(field != "plastering" ||
                                      (field === "plastering" &&
                                        invoiceData.plasteringChoice)) &&
                                      Math.round(
                                        value.calculate(
                                          field,
                                          invoiceData.choosenPlans[field],
                                          invoiceData.details.unitArea,
                                          invoiceData.unitRooms,
                                          invoiceData.details.manyFloor,
                                          invoiceData.selectedUnitTypeFactor,
                                          invoiceData.area_factor,
                                          invoiceData.airConditioners,
                                          invoiceData.airConditionersWithReception
                                        )
                                      ).toLocaleString("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      })}{" "}
                                    {t("EGP")}
                                    {field === "plastering" &&
                                      !invoiceData.plasteringChoice && <>0</>}
                                  </td>
                                  <td className="invoice-percentage">
                                    {(field != "plastering" ||
                                      (field === "plastering" &&
                                        invoiceData.plasteringChoice)) &&
                                      Math.round(
                                        (value.calculate(
                                          field,
                                          invoiceData.choosenPlans[field],
                                          invoiceData.details.unitArea,
                                          invoiceData.unitRooms,
                                          invoiceData.details.manyFloor,
                                          invoiceData.selectedUnitTypeFactor,
                                          invoiceData.area_factor,
                                          invoiceData.airConditioners,
                                          invoiceData.airConditionersWithReception
                                        ) /
                                          invoiceData.totalPrice) *
                                          100
                                      ) + " %"}

                                    {field === "plastering" &&
                                      !invoiceData.plasteringChoice && <>0 %</>}
                                  </td>
                                </tr>
                              );
                            }
                          })}


                          {uponRequest.map(plan => <tr  key={plan} >
                            <td className="cs-width_6 bold-text">
                                    {plan}
                                  </td>
                                  <td className="cs-width_6 cs-text_center">
                                    Upon request
                                  </td>
                                  <td></td>
                                  <td></td>

                              
                          </tr>)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="cs-invoice_footer">
                    <table>
                      <tbody>
                        <tr className="cs-border_none cs-focus_bg">
                          <td className="cs-width_4 cs-text_center cs-border_top_0 cs-bold cs-f16 cs-primary_color">
                            {t("Total Price")}
                          </td>
                          <td className="cs-width_4 cs-text_center cs-border_top_0 cs-bold cs-f16 cs-primary_color">
                            {Math.round(invoiceData.totalPrice).toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                            {t("EGP")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <p style={{marginTop: "5px"}}>The prices listed are all-inclusive of all applicable taxes according to Egyptian taxation laws.</p>

                <div className="cs-note">
                  <div className="cs-note_right">
                    <p className="cs-mb0">
                      <b className="cs-primary_color cs-bold">Confirmation Fee for Site Visit: 1000 EGP (Five Hundred Egyptian pounds only)</b>
                    </p>
                    <p className="cs-m0">
                    Client's approval of the priced items and confirmation of services is required before we proceed with the site visit. This approval can be
confirmed through a recorded phone call or email. Once your approval is received and confirmed, a non-refundable confirmation fee is
required. You can make this payment securely online through our payment portal. Once the payment is received, our agent will schedule a
site visit at your convenience.
                    </p>
                  </div>
                </div>
                
                <div className="cs-note">
                  <div className="cs-note_right">
                    <p className="cs-mb0">
                      <b className="cs-primary_color cs-bold">{t("Note")}:</b>
                    </p>
                    <p className="cs-m0">
                      1. After agreement on the material categories, we kindly ask that you visit our office for the final material approval. This ensures that we
                      provide you with the best possible products for your dream home
                      <br />
                      2. Please note that our website provides a precise estimate of the cost of your project. However, to provide the final approval on prices, we
                      will need to conduct a site visit to ensure that everything is in order.
                      <br />
                      3. Please note that a detailed breakdown of prices and taxes will be provided in a separate, detailed invoice, which will be attached to the
                      finalized contract after your approval.

                    </p>
                  </div>
                </div>
             
              </div>

              <div className="cs-invoice_btns cs-hide_print">
                <a
                  id="download_btn"
                  download
                  href={"https://api.10sap.com/api/userUnit/"+params.id+"/invoice?Authorization=Bearer "+localStorage.getItem('access_token')}
                  className="cs-invoice_btn invoiceBtn"
                >
                  <span>{t("Download")}</span>
                </a>
                <Link
                  className="cs-invoice_btn invoiceBtn"
                  to={
                    (i18next.language === "ar" ? "/ar" : "") +
                    "/make-visit/" +
                    params.id
                  }
                >
                  {t("Apply for Site Visit")}
                </Link>
              </div>
            </React.Fragment>
          )}
          {!loading && Object.keys(invoiceData).length === 0 && (
            <Typography
              textAlign="center"
              className="mr"
              gutterBottom
              variant="h5"
              component="h5"
            >
              {t("Invoice Not Found")}
            </Typography>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
