import { Alert, Button, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../hooks/auth";
import "../../login/form/Form.css"
import {useRef} from 'react'


export default function Form(){

    let dispatch = useDispatch();

    let [loginError,setLoginError] = React.useState(false)

    let [emailError,setEmailError] = React.useState(false)
    let [passError,setPassError] = React.useState(false)
    let [nameError,setNameError] = React.useState(false)
    let [passConError,setPassConError] = React.useState(false)
    let [phoneError,setPhoneError] = React.useState(false)

    let [emailTouched,setEmailTouched] = React.useState(false)
    let [passTouched,setPassTouched] = React.useState(false)
    let [nameTouched,setNameTouched] = React.useState(false)
    let [passConTouched,setPassConTouched] = React.useState(false)
    let [phoneTouched,setPhoneTouched] = React.useState(false)

    let emailIn = useRef()
    let passwordIn = useRef()
    let passConIn = useRef()
    let nameIn = useRef()
    let phoneIn = useRef()

    function emailChanged(event){

        setEmailTouched(true)

        let email = event.target.value
        
        if( email === '' ){
            setEmailError(true)
        }else{
    
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(email.match(mailformat))
            {
                setEmailError(false)
            }
            else
            {
                setEmailError(true)
            }
    
        }

    }

    function passChanged(event){

        setPassTouched(true)
        let pass = event.target.value
        
        if( pass === '' ){
            setPassError(true)
        }else{
            setPassError(false)
        }

    }

    function nameChanged(event){

        setNameTouched(true)
        let name = event.target.value
        
        if( name === '' ){
            setNameError(true)
        }else{
            setNameError(false)
        }

    }

    function phoneChanged(event){

        setPhoneTouched(true)
        let phone = event.target.value
        if( phone === '' ){
            setPhoneError(true)
        }else{
            if( phone.length !== 11 ){
                setPhoneError(true)
            }else{
                setPhoneError(false)
            }
        }

    }

    function passConChanged(event){
        let password = passwordIn.current.children[1].children[0].value
        setPassConTouched(true)
        let passCon = event.target.value
        if( passCon === '' ){
            setPassConError(true)
        }else{
            if( passCon !== password ){
                setPassConError(true)
            }
            else if( passCon === password ){
                setPassConError(false)
            }
        }

    }

    function registerHandler(event){
        event.preventDefault()
        let email = emailIn.current.children[1].children[0].value
        let pass = passwordIn.current.children[1].children[0].value
        let name =  nameIn.current.children[1].children[0].value
        let passCon = passConIn.current.children[1].children[0].value
        let phone = phoneIn.current.children[1].children[0].value

        let reqObj = {
            email: email,
            password: pass,
            password_confirmation: passCon,
            phone: phone,
            name: name
        }
        
        axios.post('https://api.10sap.com/api/register',reqObj).then(
            (response) => {
                if( response.data.success ){
                    localStorage.setItem('access_token',response.data.token)
                    localStorage.setItem('userData',JSON.stringify(response.data.user))
                    dispatch(authActions.loginHandler())
                }else{
                    setLoginError(response.data.errors[0])
                    setTimeout(() => {
                        setLoginError('')
                    },1500)
                } 
            } 
        )
    }

    let formIsValid = emailTouched && !emailError && !passError && passTouched && !passConError && passConTouched && !nameError && nameTouched && !phoneError && phoneTouched ? true : false

    return(

        <React.Fragment>
            
            <Grid container>
                <form onSubmit={registerHandler}>
        
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                            onChange={emailChanged}
                            size="small"
                            margin="normal"
                            fullWidth 
                            type="email"
                            label="Email"
                            ref={emailIn}
                            error={emailError}
                            helperText={emailError ? "Email Not Valid" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                            onChange={nameChanged}
                            size="small"
                            margin="normal"
                            fullWidth 
                            ref={nameIn}
                            type="text"
                            label="Name"
                            error={nameError}
                            helperText={nameError ? "Name Is Required" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                            onChange={passChanged}
                            size="small"
                            margin="normal"
                            fullWidth 
                            ref={passwordIn}
                            type="password"
                            label="Password"
                            error={passError}
                            helperText={passError ? "Field Is Required" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                            onChange={passConChanged}
                            size="small"
                            margin="normal"
                            fullWidth 
                            ref={passConIn}
                            type="password"
                            label="Confirm Password"
                            error={passConError}
                            helperText={passConError ? "Field Is Required" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                            ref={phoneIn}
                            onChange={phoneChanged}
                            size="small"
                            margin="normal"
                            fullWidth 
                            type="number"
                            label="Phone"
                            error={phoneError}
                            helperText={phoneError ? "Phone Not Valid" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <FormControlLabel control={<Checkbox size="small" defaultChecked />} label="Accept our Terms and Conditions." />
                        </Grid>

                        {
                            loginError &&
                            <Grid item xs={12} md={12}>
                                <Alert severity="error">{loginError}</Alert>
                            </Grid>
                        }

                        <Grid item xs={12} md={12}>
                            <div className="btnsCon">
                                <Button type="submit" size="normal" disabled={!formIsValid} variant="contained">Sign up</Button>
                            </div>     
                        </Grid>

                    </Grid>

                </form>
            </Grid>

        </React.Fragment>

    )

}